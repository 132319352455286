import React, { Component } from 'react';
import Layout from '../components/layout'
import { Helmet } from 'react-helmet';
import StockQuotesList from '../components/stockquotes/stockquoteslist'
import StockQuoteForm from '../components/stockquotes/stockquoteform'
import background from "../images/ticker.jpg"
import  { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
const ReCaptchaStockQuoteForm = withGoogleReCaptcha(StockQuoteForm);

class TOTMIdeas extends Component {

  constructor(props){
    super(props);
    this.state =  {
      error: null,
      isLoaded: false,
      ideas: [],
      refreshList: Math.round(Math.random()*1000)
    }

    this.handleListRefresh = this.handleListRefresh.bind(this);
  }

  handleListRefresh(){
    this.setState({refreshList: Math.round(Math.random()*1000)});
    console.log("handleListRefresh", this.state);
  }

  render(){
    return (
      <Layout>
          <Helmet>
            <title>Three Wise Men Trade Ideas</title>
        </Helmet>
          <section id="page-title" className="page-title-parallax page-title-dark" style={{backgroundImage: `url(${background})`, padding: '120px 0'}} data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
              <div className="container clearfix page-title">
                  <h1>Three Wise Men Trade Ideas</h1>
                  <span>Submit three wise men trade ideas to share with other fishermen</span>
              </div>
          </section>
          <section id="content">
              <div className="content-wrap">
                  <div className="container clearfix">
                    <div className="form-widget">
                      <div className="form-result"></div>
                        <div className="row">
                          <div className="col-lg-4">
                            <ReCaptchaStockQuoteForm refresh={this.handleListRefresh}/>
                          </div>
                          <div className="col-lg-7 offset-lg-1">
                            <StockQuotesList refresh={this.state.refreshList}/>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>    
          </section>          
      </Layout>
    )
  }
}

export default TOTMIdeas