import React, { Component } from 'react';
import validator from 'email-validator';

class StockQuoteForm extends Component {
    constructor(props){
        super(props);
        this.state =  {
          email: "",
          "name": "",
          ticker: "",
          submitted: false,
          show_message: false,
          show_error: false,
          form_message: null,
          form_error: null,
          token: null
        }

        
        this.handleRefresh = props.refresh;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
      }
      

      setToken(value){
          this.setState({token: value})
      }
      
     handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }
    
    validateForm(){
        let validity = {
            valid: true,
            message: null
        };

        if (this.state["name"].length == 0) {
            validity.message = "Please submit a valid name";
            validity.valid = false;
            return validity; 
        }
    
        else if (!validator.validate(this.state.email)) {
            validity.message = "Please submit a valid email address";
            validity.valid = false;
            return validity;     
        }
    
        else if (this.state.ticker.length < 1 || this.state.ticker.length > 5) {
            validity.message = "Please submit a valid stock ticker";
            validity.valid = false;
            return validity;
        }

        return validity;
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({
            submitted: true,
            token: await this.props.googleReCaptchaProps.executeRecaptcha('homepage')
        });

        let validity = this.validateForm();

        if(!validity.valid){
            this.setState({
                submitted: false,
                show_error: true,
                show_message: false,
                form_error: validity.message
            });
            return;
        }
        let values = {
            email: this.state.email,
            name: this.state.name,
            ticker: this.state.ticker,
            recaptcha: this.state.token
        };

        fetch("https://api.totmschool.com/stockquote", {
            body: JSON.stringify(values),
            method: 'POST'
        })
        .then(response => {
            this.setState({submitted: false});
            if (response.status >= 200 && response.status < 300) {
                this.setState({
                    show_message: true,
                    show_error: false,
                    form_message: "Your Stock Quote was submitted successfully!",
                    refresh: true
                });
                this.handleRefresh();
              } else {
                response.json().then((result) => {
                    this.setState({
                        show_message: false,
                        show_error: true,
                        form_error: result.error
                    });
                });
              }
        }).catch(err => {
            this.setState({
                submitted: false,
                show_error: true,
                show_message: false,
                form_error: "Your quote could not be submitted. Please try again!"
            });
            console.log(err)
        });

        this.setState({description: ''});
    }

    render(){
        
              return (
                <form className="row" onSubmit={this.handleSubmit}>
                    <div className="form-process" style={{ display: this.state.submitted ? "block" : "none" }}>
                        <div className="css3-spinner">
                            <div className="css3-spinner-scaler"></div>
                        </div>
                    </div>
                    <h3>Submit three wise men trade ideas!</h3>
                    <div className="col-12 form-group">
                    <label>Name:</label><br />
                    <input type="text" id="quote-name" name="name" className="form-control required" value={this.state.name} onChange={this.handleInputChange} />
                    </div>
                    <div className="w-100"></div>
                    <div className="col-12 form-group">
                    <label>Email:</label>
                    <input type="email" name="email" id="quote-email" className="form-control required" value={this.state.email}  onChange={this.handleInputChange} placeholder="user@company.com" />
                    </div>
                    <div className="col-12 form-group">
                    <label>Ticker:</label><br />
                    <input type="text" id="ticker" name="ticker" className="form-control required" value={this.state.ticker} onChange={this.handleInputChange} />
                    </div>
                    <div className="col-12">
                    <button type="submit" name="quote-submit" className="btn btn-primary">Submit Idea</button>
                    </div>
                    <br />
                    <div style={{display: this.state.show_message ? "block" : "none", marginTop: "10px"}} className="alert alert-success" role="alert">
                        {this.state.form_message}
                    </div>
                    <div style={{ display: this.state.show_error ? "block" : "none", marginTop: "10px" }} className="alert alert-danger" role="alert">
                        {this.state.form_error}
                    </div>
                </form> 
              )
      }
}

export default StockQuoteForm