import React, { Component } from 'react';
import Moment from 'react-moment';
import { FaRedoAlt } from 'react-icons/fa';

class StockQuotesList extends Component {
    constructor(props){
        super(props);
        this.state =  {
          error: null,
          isLoaded: false,
          ideas: [],
          refresh: props.refresh
        }

        this.fetchQuotes = this.fetchQuotes.bind(this);
      }

      componentDidMount(){
        this.fetchQuotes();
      }
      
      
      fetchQuotes(){
        this.setState({
          isLoaded: false
        });
        fetch("https://api.totmschool.com/stockquote")
          .then(res => res.json())
          .then(
            (result) => {
              if(result.length > 0){
              this.setState({
                isLoaded: true,
                ideas: result.sort(function(a,b){
                  var key1 = new Date(a.created);
                  var key2 = new Date(b.created);

                  if (key1 > key2) {
                      return -1;
                  } else if (key1 == key2) {
                      return 0;
                  } else {
                      return 1;
                  }
                })
              });
            }
            else{
              this.setState({
                isLoaded: true,
                error: "There are no trade ideas to show at this time"
              });
            }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error: error.message
              });
            }
          )
      }

      render(){
        const { error, isLoaded, ideas } = this.state;
          if (error) {
            return <div className="alert alert-primary" role="alert"> {this.state.error}</div>;
          }
          else if(!isLoaded){
            return (<div className="alert alert-primary" role="alert"> 
                    <div className="spinner-border text-dark" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                      <span style={{marginLeft: "20px"}}>Loading Trade Ideas</span>
            </div>);
          }
          else{
              return (
                <div>
                  <div className="row col-mb-50 button-padding">
                    <div className="col-4 offset-8">
                        <button name="next-testimonial" className="btn btn-secondary"   style={{float: "right"}} onClick={this.fetchQuotes}><FaRedoAlt /> Refresh Ideas</button>
                    </div>
                </div>
                <ul className="testimonials-grid grid-1 grid-md-2 grid-lg-3" key={this.props.refresh}>
                {ideas.map(idea => (
                  <li key={`${Math.round(Math.random()*10000)}`} className="grid-item">
                  <div className="testimonial">
                    <div className="testi-content">
                      <a href={`https://www.barchart.com/stocks/quotes/${idea.ticker}/overview`} target="_blank"><h3 className="ticker-link">{idea.ticker}</h3></a>
                      <div className="testi-meta">
                        <a href={`mailto:${idea.email}`}>{idea.name}</a>
                        <span><Moment format="MM/DD/YYYY h:mm A">{idea.created}</Moment></span>
                      </div>
                    </div>
                  </div>
                </li>
                ))}
              </ul>
              </div>
              )
          }
      }
}

export default StockQuotesList